<template>
    <v-dialog v-model="dialog" v-if="dialog" scrollable persistent max-width="300">
        <v-card>
            <v-card-title>
                <span class="headline">Conta de Cobrança</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="agencia" label="Agência" v-mask="'####'" placeholder=" "
                                          :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="agenciaDigito" label="Dígito" placeholder=" "
                                          :rules="requiredRules" readonly disabled>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="conta" label="Conta" v-mask="'######'" placeholder=" "
                                          :rules="requiredRules" required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="contaDigito" label="Dígito" placeholder=" "
                                          :rules="requiredRules"  disabled>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save()">
                            Salvar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EditarContaCobrancaDialog",
    data() {
        return {
            dialog: false,
            form1Valid: true,
            agencia: null,
            agenciaDigito: null,
            conta: null,
            contaDigito: null,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
        }
    },
    watch:{
        agencia(valor){
            this.agenciaDigito = this.calculaDigito(valor)
        },
        conta(valor){
            this.contaDigito = this.calculaDigito(valor)
        }
    },
    methods:{
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            // this.$refs.form1.reset();
            this.$refs.form1.resetValidation();

        },
        calculaDigito(v){
            let calc = (v1, v2)=>{
                let c = (parseInt(v1/1000000)*2);
                c = c + (parseInt((v1%1000000)/100000)*7);
                c = c + (parseInt((v2%100000)/10000)*6);
                c = c + (parseInt((v1%10000)/1000)*5);
                c = c + (parseInt((v1%1000)/100)*4);
                c = c + (parseInt((v1%100)/10)*3);
                c = c + (parseInt((v1%10)/1)*2);
                return c % 11;
            }

            if(11 - calc(v, 16) === 11){
                return 0
            }else{
                let r = calc(v, v);
                return (11 - r === 10) ? "P" : 11 - r;
            }
        },
        save(){

        }
    }
}
</script>

<style scoped>

</style>