<template>
    <page v-if="projeto">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'projetos'}">Projetos</v-breadcrumbs-item>
        </v-breadcrumbs>
        <lembretes-fragment ref="lembretesFragment" :projeto="projeto" />

        <div v-if="projeto">
            <div>
                <div>
                    <span class="title font-weight-regular d-inline-flex" style="line-height: normal; align-items: center">
                        {{projeto.cliente}}
                        <v-menu :close-on-content-click="false"
                                offset-y open-on-hover>
                            <template v-slot:activator="{ on }">
                                <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                    <v-icon >mdi-information-variant</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data de solicitação</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{projeto.dataSolicitacao | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
<!--                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data limite</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{fiscalizacao.dataLimite | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="fiscalizacao.solicitacoesProrrogacao.find((s) => s.dataLimiteDefinida !== null)">
                                        <v-chip @click="$refs.solicitacoesProrrogacaoHistoricoDialog.openDialog(fiscalizacao.solicitacoesProrrogacao)" label x-small color="red" dark>DATA PRORROGADA</v-chip>
                                    </v-list-item-action>
                                </v-list-item>-->
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data da criação</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{projeto.createdAt | moment('DD/MM/YYYY HH:mm')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="projeto.dataEnvio">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-light">Data de envio</v-list-item-title>
                                        <v-list-item-subtitle class="font-weight-bold">{{projeto.dataEnvio | moment('DD/MM/YYYY')}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-menu offset-y >
                            <template v-slot:activator="{ on }">
                                <v-btn class="ml-2" small icon color="grey" outlined v-on="on">
                                    <v-icon small>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item :disabled="finalizado" @click="editGeneralInfoDialog">
                                    <v-list-item-title>Editar</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="$refs.lembretesFragment.newLembreteDialog()">
                                    <v-list-item-title>Criar Lembrete</v-list-item-title>
                                </v-list-item>
<!--                                <v-list-item :disabled="fiscalizacao.solicitacoesProrrogacao.find((s) => s.dataLimiteDefinida === null)" @click="$refs.solicitarProrrogacaoDialog.openDialog(fiscalizacao)">
                                    <v-list-item-title>Solicitar Prorrogação</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="desfinalizarFiscalizacao(fiscalizacao.id)" v-if="finalizado">
                                    <v-list-item-title class="red&#45;&#45;text" >Desfazer finalização</v-list-item-title>
                                </v-list-item>-->
                            </v-list>
                        </v-menu>

                        <!--<v-btn v-if="finalizado" :loading="downloadingXls" :disabled="downloadingXls"
                               class="ml-2" small outlined icon color="indigo" @click="downloadLaudo('pdf')">
                            <v-icon small>mdi-file-download-outline</v-icon>
                        </v-btn>-->
                    </span>
                    <div v-if="projeto.supervisedBy" style="opacity: 0.6; float: right" class="d-inline-flex align-center">
                        <div class="d-flex align-center">

                            <div>
                                <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Responsável</p>
                                <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{projeto.supervisedBy.nome}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <span class="caption text-uppercase">{{projeto.tipo.nome}}</span>
            </div>

            <v-row>
                <v-col>
                    <v-card color="blue-grey darken-3" dark outlined class="mb-3" v-if="projeto.dataEnvio">
                        <v-row class="pa-4 " no-gutters>
                            <v-col class="shrink mr-4" style="min-width: 300px" v-if="projeto.caminhoPolyline">
                                <v-img :src="getCaminhoImageUrl(projeto)"
                                       width="100%" class="fill-height" >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-col>
                            <v-col class="">
                                <div style="border-radius: 4px"
                                     class="green py-1 text-center mb-2">
                                    <span class="title-1 white--text font-weight-medium text-uppercase">
                                        Projeto Finalizado
                                    </span>
                                </div>
                                <v-row no-gutters v-if="projeto.dataFinalizacao">
                                    <v-col cols="">
                                        <span class="label" style="color: #d5d5d5 !important;">Data da Finalização</span>
                                        {{(projeto.dataFinalizacao) | moment('DD [de] MMMM [de] YYYY')}}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="6"  v-if="projeto.distanciaPercorrida">
                                        <span class="label" style="color: #d5d5d5 !important;">Distância Percorrida</span>
                                        <template>
                                            {{projeto.distanciaPercorrida}} km
                                            <span v-if="projeto.distanciaPercorrida > 500"> ({{projeto.distanciaPercorrida - 500}} km excedidos)</span>
                                        </template>
                                    </v-col>
                                    <v-col cols="6" v-if="projeto.pontoPartida">
                                        <span class="label" style="color: #d5d5d5 !important;">Ponto de Partida</span>
                                        <router-link :to="{ name: 'cidade_view', params: { id: parseInt(projeto.pontoPartida.cidade.id) }}">
                                            {{projeto.pontoPartida.cidade.nome}}/{{projeto.pontoPartida.cidade.estado.sigla}}
                                        </router-link>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="projeto.motivoDoAtraso">
                                    <v-col>
                                        <span class="label" style="color: #d5d5d5 !important;">Motivo do Atraso</span>
                                        {{projeto.motivoDoAtraso}}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!--INFO-->
                    <v-card outlined class="mb-6 pa-4">
                        <v-row>
                            <v-col>
                                <v-row no-gutters>
                                    <v-col>
                                        <span class="label" style="">Idêntificação</span>
                                        {{formatCpfCnpj(projeto.identificacao)}}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <span class="label" style="">Agencia Solicitante<v-chip v-if="projeto.agenciaSolicitante && projeto.agenciaSolicitante.fechadaEm" x-small color="red" label dark class="px-1">FECHADA</v-chip></span>
                                        <router-link :to="{ name: 'agencia_view', params: { id: parseInt(projeto.agenciaSolicitante.id) }}" v-if="projeto.agenciaSolicitante">
                                            {{projeto.agenciaSolicitante.nome}}
                                            {{projeto.agenciaSolicitante.codigo | codigo}}
                                        </router-link>
                                        <v-btn v-else color="orange"
                                               dark  x-small rounded depressed
                                               @click="openAlteraAgenciaDialog">
                                            <v-icon left small>mdi-alert-circle-outline</v-icon>
                                            Definir Agência
                                        </v-btn>

                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4" style="background-color: whitesmoke;">
                                <v-subheader class="text-uppercase caption flex" style="align-items: center">
                                    <span class="blue-grey--text mr-2">Dados de cobrança</span>

                                    <v-btn rounded depressed x-small color="primary" :disabled="finalizado" @click="editarContaCobranca">Editar</v-btn>
                                </v-subheader>
                                <template v-if="projeto.agencia || projeto.agenciaDigito || projeto.conta || projeto.contaDigito">
                                    <v-row>
                                        <v-col class="shrink">
                                            <span class="label" style="">Agencia</span>
                                            {{projeto.agencia}}
                                        </v-col>
                                        <v-col>
                                            <span class="label" style="">Dígito</span>
                                            {{projeto.agenciaDigito}}
                                        </v-col>
                                        <v-col class="shrink">
                                            <span class="label" style="">Conta</span>
                                            {{projeto.conta}}
                                        </v-col>
                                        <v-col>
                                            <span class="label" style="">Dígito</span>
                                            {{projeto.contaDigito}}
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-alert v-else color="yellow" dense icon="mdi-alert" class="mt-4 mx-4">
                                    Nenhuma conta cadastrada.
                                </v-alert>
                            </v-col>
                        </v-row>
<!--                        <v-row no-gutters>
                            <v-col cols="3">
                                <span class="label">Chave da Operação</span>
                                {{fiscalizacao.financiamento.operacao}}<span v-if="fiscalizacao.financiamento.operacaoDigito">-{{fiscalizacao.financiamento.operacaoDigito}}</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Nº da Cédula</span>
                                {{fiscalizacao.financiamento.cedula}}
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Data da Cédula</span>
                                {{new Date(fiscalizacao.financiamento.dataCedula).toLocaleDateString()}}
                            </v-col>
                            <v-col cols="3">
                                <span class="label">Data Vencimento</span>
                                {{new Date(fiscalizacao.financiamento.dataVencimento).toLocaleDateString()}}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <span class="label" style="">Agencia <v-chip v-if="fiscalizacao.financiamento.agencia && fiscalizacao.financiamento.agencia.fechadaEm" x-small color="red" label dark class="px-1">FECHADA</v-chip></span>
                                <router-link :to="{ name: 'agencia_view', params: { id: parseInt(fiscalizacao.financiamento.agencia.id) }}" v-if="fiscalizacao.financiamento.agencia">
                                    {{fiscalizacao.financiamento.agencia.nome}}
                                    {{fiscalizacao.financiamento.agencia.codigo | codigo}}
                                </router-link>
                                <v-btn v-else color="orange"
                                       dark  x-small rounded depressed
                                       @click="openAlteraAgenciaDialog">
                                    <v-icon left small>mdi-alert-circle-outline</v-icon>
                                    Definir Agência
                                </v-btn>

                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Finalidade</span>
                                {{fiscalizacao.financiamento.finalidade.descricao}}
                                {{fiscalizacao.financiamento.finalidade.codigo | codigo}}
                            </v-col>
                            <v-col cols="6">
                                <span class="label">Empreendimento</span>
                                {{fiscalizacao.financiamento.empreendimento.descricao}}
                                {{fiscalizacao.financiamento.empreendimento.codigo | codigo}}
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Loc. Das Garantias</span>
                                {{fiscalizacao.financiamento.localGarantias}}
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="6">
                                <span class="label">Origem dos Recursos</span>
                                <span v-if="fiscalizacao.financiamento.origemRecursos">
                                    {{fiscalizacao.financiamento.origemRecursos.descricao}}
                                    {{fiscalizacao.financiamento.origemRecursos.codigo | codigo}}
                                </span>
                                <span v-else class="orange&#45;&#45;text font-weight-regular"><v-icon class="orange&#45;&#45;text" left style="font-size: 20px">warning</v-icon>Não informado</span>
                            </v-col>
                        </v-row>-->
                    </v-card>

                    <v-btn block large color="primary" class="mt-6" @click="finalizarProjeto" :disabled="finalizado">Finalizar</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-card flat class="fill-height" style="background-color: whitesmoke">
                        <v-card-text class="pa-0">
<!--                            <nota-fiscal-fragment :fiscalizacao="fiscalizacao" />
                            <v-divider></v-divider>-->
                            <anexos-fragment :projeto="projeto" :finalizado="false" />
                            <v-divider></v-divider>
                            <contatos-fragment :projeto="projeto" />
<!--                            <v-divider></v-divider>
                            <emails-fragment :fiscalizacao="fiscalizacao" />-->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <editar-conta-cobranca-dialog ref="editarContaCobrancaDialog" />
    </page>
</template>

<script>
// @ts-ignore
import page from '../../../components/Page';
// @ts-ignore
import LembretesFragment from "./fragments/view/LembretesFragment";
// @ts-ignore
import AnexosFragment from "./fragments/view/AnexosFragment";
// @ts-ignore
import ContatosFragment from "./fragments/view/ContatosFragment";
import numeral from "numeral";
import gql from "graphql-tag";
import EditarContaCobrancaDialog from "@/views/servico/projeto/EditarContaCobrancaDialog";

export default {
    name: "View",
    components: {
        EditarContaCobrancaDialog,
        page,
        LembretesFragment,
        AnexosFragment,
        ContatosFragment
    },
    filters: {
        codigo(value){
            if(value){
                return '(' + value + ')';
            }
            return '';
        }
    },
    computed: {
        finalizado() {
            //return this.fiscalizacao.dataFiscalizacao != null;
            return this.projeto.dataEnvio != null;
        },
    },
    data(){
        return{
            projeto: null,
        }
    },
    mounted() {
        numeral.locale('pt-br');
        this.getProjeto(this.$route.params.id);
    },
    methods:{
        getProjeto(id) {
            this.$apollo.query({
                query: gql`query($id: Int!) {
                    projeto(id: $id){
                        id
                        tipo{
                            id
                            nome
                        }
                        cliente
                        identificacao
                        dataSolicitacao
                        dataEnvio
                        agencia
                        agenciaDigito
                        conta
                        contaDigito
                        caminhoPolyline
                        distanciaPercorrida
                        agenciaSolicitante{
                            id
                            nome
                            codigo
                            fechadaEm
                        }
                        pontoPartida{
                            cidade{
                                id
                                nome
                                estado{
                                    nome
                                    sigla
                                }
                            }
                        }
                        createdAt
                        arquivos{
                            id
                            nome
                            createdAt
                            locked
                            tags{
                                id
                                nome
                            }
                        }
                        contatos{
                            id
                            numero
                            observacao
                            whatsapp
                        }
                        lembretes{
                            id
                            descricao
                            criadoPor{
                                id
                                nome
                                sobrenome
                                imagem{
                                    id
                                }
                            }
                            createdAt
                        }
                        supervisedBy{
                            id
                            nome
                            sobrenome
                            email
                            imagem{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    id
                },
            }).then((result) => {
                this.projeto = result.data.projeto;
                document.title = this.projeto.cliente + " - JF Assessoria Rural"
            });
        },
        editGeneralInfoDialog() {
            //this.$refs.generalInfoDialog.openDialog(this.fiscalizacao);
        },
        getCaminhoImageUrl(projeto){
            //let pontoPartida = projeto.pontoPartida.cidade.nome+"/"+projeto.pontoPartida.cidade.estado.nome;
            //let destino = fiscalizacao.financiamento.area.localizacao.cidade.nome + "/" + fiscalizacao.financiamento.area.localizacao.cidade.estado.nome
            let url = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDVmydqrR66sMvCjiFRy9yTXo3KMzJuQIw'
            url += '&size=420x250'
            //url += '&markers=label:A|'+ pontoPartida
            //url += '&markers=label:B|'+ destino
            url += '&path=weight:4%7Cenc:' + projeto.caminhoPolyline
            console.log("url", url)
            return url;
        },
        formatCpfCnpj(cpfCnpj){
            let formatCpf = (cpf) => {
                return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            }
            let formatCnpj = (cnpj) => {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5")
            }
            if(cpfCnpj.length === 11){
                return formatCpf(cpfCnpj)
            }else{
                return formatCnpj(cpfCnpj)
            }
        },
        editarContaCobranca(){
            this.$refs.editarContaCobrancaDialog.openDialog();
        },
        finalizarProjeto() {
            if (this.projeto.agencia == null && this.projeto.agenciaDigito == null && this.projeto.conta == null && this.projeto.contaDigito == null) {
                this.$dialog.warning({
                    text: 'Defina agência e conta corretamente para poder continuar',
                    title: 'Atenção',
                    actions: {text: "ok"}
                });
                return
            }

            if (this.projeto.localizacao == null) {
                this.$dialog.warning({
                    text: 'Defina uma localização antes de finalizar',
                    title: 'Atenção',
                    actions: {text: "ok"}
                });
                return
            }

            //verificar anexo de projeto
            //verificar anexo de comprovante de visita
            //verificar email


            //this.$refs.finalizarDialog.openDialog(this.fiscalizacao, this.fiscalizacaoVencida());
        },
    }
}
</script>

<style scoped lang="scss">
.label{
    color: #616161 !important;
    caret-color: #616161 !important;

    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;

    display: block !important;
}
</style>