<template>
    <div>
        <v-alert color="yellow" dense class="py-0"
                 v-for="(lembrete, index) in projeto.lembretes">
            <v-row align="center">
                <v-col class="shrink">
                    <v-icon>
                        mdi-alert
                    </v-icon>
                </v-col>
                <v-col class="grow">
                    <span class="link" @click="editLembrete(lembrete, index)">{{lembrete.descricao}}</span>
                </v-col>
                <v-col class="shrink text-right d-flex">
                    <div class="d-flex flex-column justify-center mr-4">
                        <span class="caption text-no-wrap" style="line-height: 12px">{{lembrete.createdAt | moment("DD/MM/YYYY")}}</span>
                        <span class="caption text-no-wrap" style="line-height: 12px">{{lembrete.createdAt | moment("HH:mm [hrs]")}}</span>
                    </div>
                    <v-btn elevation="1" small :disabled="removing"
                           @click="dispensarLembrete(lembrete.id, index)">Dispensar</v-btn>
                    <v-avatar color="indigo" size="28" class="ml-4">
                        <usuario-avatar :usuario="lembrete.criadoPor" :text-class="'caption'" />
                    </v-avatar>
                </v-col>
            </v-row>
        </v-alert>
        <add-lembrete-dialog ref="addLembreteDialog" @saved="onLembreteSaved" @edited="onLembreteEdited" />
    </div>
    
</template>

<script>
    // @ts-ignore
    import AddLembreteDialog from "../../AddLembreteDialog";
    import gql from "graphql-tag";
    import UsuarioAvatar from "@/components/UsuarioAvatar";
    export default {
        name: "LembretesFragment",
        components: {
            UsuarioAvatar,
            AddLembreteDialog
        },
        props: {
            projeto: null,
        },
        data(){
            return{
                removing: false,
            }
        },
        methods:{
            newLembreteDialog(){
                this.$refs.addLembreteDialog.openDialog();
            },
            editLembrete(lembrete, index){
                lembrete.index = index;
                this.$refs.addLembreteDialog.openDialog(lembrete);
            },
            dispensarLembrete(id, index){
                this.removing = true;
                this.$apollo.mutate({
                    mutation: gql`mutation ($id:Int!){
                            removeLembrete(id: $id)
                        }`,
                    variables: {
                        id: id
                    }
                }).then((result) => {
                    this.removing = false;
                    this.projeto.lembretes.splice(index, 1);
                    this.$store.commit("lembrete/decreaseTotalLembretes");
                }).catch(()=>{
                    this.removing = false;
                });
            },
            onLembreteSaved(lembrete){
                this.projeto.lembretes.push(lembrete);
                this.$store.commit("lembrete/incrementTotalLembretes");
            },

            onLembreteEdited(lembrete, index){
                let lembreteExisted = this.projeto.lembretes[index];
                lembreteExisted = Object.assign(lembreteExisted, lembrete);
            }
        }
    }
</script>

<style scoped>
    .link{
        cursor: pointer;
    }
    .link:hover{
        text-decoration: underline;
    }
</style>